/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import * as icons from "../utils/icons"

function concatArticles(node) {
  return [
    ...(Array.isArray(node.articles) ? node.articles : []),
    ...(Array.isArray(node.sections)
      ? node.sections.flatMap((section) =>
        Array.isArray(section.articles) ? section.articles : []
      )
      : []),
  ]
}

class HelpCenterIndex extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={this.props.data.site.siteMetadata.title}
        description={this.props.data.site.siteMetadata.description}
      >
        <SEO title={this.props.data.site.siteMetadata.title} skipSuffix />
        {this.props.data.collections.edges.map(({ node }, index) => {
          const articlesOfCollection = concatArticles(node)

          let icon =
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          if (node.icon === "money") {
            icon =
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
          }
          else if (node.icon === "template") {
            icon =
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
              </svg>
          }

          return (
            <Link to={node.fields.slug} key={node.id} className="hover:no-underline shadow-none">
              <article className="bg-white shadow rounded py-8 px-2 relative z-10 no-underline overflow-hidden w-full flex outline-none first:mt-2 mt-0 transition-all duration-150 hover:shadow-md mb-5 hover:bg-gray-25">
                <div className="hidden sm:flex flex-1 items-center justify-center px-2 pb-1">
                  <div className="w-12 text-gray-400">
                    {icon}
                  </div>
                </div>
                <div className="flex-grow-6 px-6 sm:px-0">
                  <header>
                    <h3 className="mb-2 text-blue-700 hover:text-blue-800 leading-4">
                      {node.title}
                    </h3>
                  </header>
                  <section className="text-gray-600 text-sm mb-0.5">
                    {node.description}
                  </section>
                  <small className="text-gray-500 text-xs">
                    {articlesOfCollection.length}{" "}
                    {(() => {
                      switch (articlesOfCollection.length) {
                        case 0:
                          return this.props.data.site.siteMetadata.texts
                            .articlesInCollectionZeroText
                        case 1:
                          return this.props.data.site.siteMetadata.texts
                            .articlesInCollectionOneText
                        case 2:
                          return this.props.data.site.siteMetadata.texts
                            .articlesInCollectionTwoText
                        default:
                          return this.props.data.site.siteMetadata.texts
                            .articlesInCollectionMultipleText
                      }
                    })()}
                  </small>
                </div>
              </article>
            </Link>
          )
        })}
      </Layout>
    )
  }
}

export default HelpCenterIndex

export const pageQuery = graphql`
  fragment IndexArticleFragment on File {
    childMarkdownRemark {
      id
    }
  }
  query {
    site {
      siteMetadata {
        title
        description
        texts {
          articlesInCollectionZeroText
          articlesInCollectionOneText
          articlesInCollectionTwoText
          articlesInCollectionMultipleText
        }
      }
    }
    collections: allCollectionsYaml {
      edges {
        node {
          id
          title
          icon
          description
          articles {
            file {
              ...IndexArticleFragment
            }
          }
          sections {
            articles {
              file {
                ...IndexArticleFragment
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
